<template>
  <div>
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <b-card v-if="stocktakelist">
            <el-table
              stripe
              class="table-responsive table"
              header-row-class-name="thead-light"
              :data="stocktakelist"
              @row-click="row_clicked_event"
              size="small"
              id="stocktakelisttable"
            >
              <el-table-column>
                <template slot="header">
                  <div class="w-100 p-0 text-center labeldiv">
                    {{ $t('Store') }}
                  </div>
                  <select
                    class="form-control"
                    v-model="search_LocationID"
                    @change="get_list"
                  >
                    <option value=""></option>
                    <option
                      v-for="(dt, idx) in storelist"
                      :key="idx"
                      :value="dt.LocationID"
                    >
                      {{ dt.LocationID }} - {{ dt.LocationName }}
                    </option>
                  </select>
                </template>
                <template slot-scope="scope">
                  {{ scope.row.LocationID }} - {{ scope.row.LocationName }}
                </template>
              </el-table-column>
              <el-table-column prop="StockTakeDate">
                <template slot="header">
                  <div class="w-100 p-0 text-center labeldiv">
                    {{ $t('Stocktake Date') }}
                  </div>
                  <flat-picker
                    :config="picker_config"
                    class="form-control datepicker"
                    v-model="search_StockTakeDate"
                  >
                  </flat-picker>
                </template>
              </el-table-column>
              <el-table-column prop="UserName">
                <template slot="header">
                  <div class="w-100 p-0 text-center labeldiv">
                    {{ $t('Create By') }}
                  </div>
                  <select
                    class="form-control"
                    v-model="search_CreateBy"
                    @change="get_list"
                  >
                    <option value=""></option>
                    <option
                      v-for="(dt, idx) in employeelist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.UserName }}
                    </option>
                  </select>
                </template>
              </el-table-column>
              <el-table-column prop="Status">
                <template slot="header">
                  <div class="w-100 p-0 text-center labeldiv">
                    {{ $t('Status') }}
                  </div>
                  <select
                    class="form-control"
                    v-model="search_Status"
                    @change="get_list"
                  >
                    <option value=""></option>
                    <option value="Round1">Round1</option>
                    <option value="Round2">Round2</option>
                    <option value="Complete">Complete</option>
                  </select>
                </template>
              </el-table-column>
              <el-table-column prop="Remarks">
                <template slot="header">
                  <div class="w-100 p-0 text-center labeldiv">
                    {{ $t('Remarks') }}
                  </div>
                  <input
                    class="form-control"
                    v-model="search_Remarks"
                    @keyup="get_list_delay"
                  />
                </template>
              </el-table-column>
              <el-table-column>
                <template slot="header">
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="small"
                    @click="add_stocktake"
                  >
                    {{ $t("Add") }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-row style="margin-top: 20px">
              <el-col :span="20">
                <div>
                  <b-pagination
                    v-model="current_page"
                    :total-rows="page_total"
                    :per-page="per_page"
                  ></b-pagination>
                </div>
              </el-col>
              <el-col :span="4">
                <div>
                  <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ $t("Max Rows per page") }} {{ this.per_page
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="page_option in per_page_option"
                        :key="page_option"
                        :command="page_option"
                        >{{ page_option }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-col>
            </el-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="my-modal"
      :title="$t('Stocktake Detail')"
      id="stock_take_modal"
      size="xl"
    >
      <div v-if="cur_order">
        <form v-if="cur_order.Status == ''">
          <el-row>
            <el-col :span="8">
              <el-row>
                <el-col :span="6" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Store") + " : " }}
                </el-col>
                <el-col :span="18">
                  <el-select v-model="cur_order.LocationID">
                    <el-option
                      v-for="store in storelist"
                      :key="store.LocationID"
                      :value="store.LocationID"
                      :label="store.LocationName"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="8">
              <el-row>
                <el-col :span="12" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Inventory updated in") + " : " }}
                </el-col>
                <el-col :span="12">
                  <el-select v-model="cur_order.UpdatePeriod">
                    <el-option
                      v-for="period in update_period_list"
                      :key="period.value"
                      :value="period.value"
                      :label="period.name"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6">
              <el-row>
                <el-col :span="18" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Include zero stock") + " : " }}
                </el-col>
                <el-col :span="6">
                  <b-form-checkbox
                    class="mt-2 checkboxcheck form-checkbox"
                    v-model="cur_order.isZeroQtyIncluded"
                    value="1"
                    unchecked-value="0"
                    switch
                    inline
                  >
                  </b-form-checkbox>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="24">
              <el-row>
                <el-col :span="2" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Remarks") + " : " }}
                </el-col>
                <el-col :span="22">
                  <b-textarea
                    :placeholder="$t('Remarks')"
                    type="textarea"
                    rows="2"
                    max-rows="2"
                    v-model="cur_order.Remarks"
                    no-resize
                  ></b-textarea>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="24">
              <el-row>
                <el-col :span="2" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Scope") + " : " }}
                </el-col>
                <el-col :span="22">
                  <el-cascader
                    :options="idxdepartmentlist"
                    :props="{ multiple: true,
                              value: 'IDn',
                              label: 'Name1',
                              children: 'Categorys',
                              emitPath: 'false' }"
                    style="width: 100%"
                    v-model="cur_order.scope"
                    clearable>
                  </el-cascader>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </form>
        <form v-else>
          <el-row :gutter="10" v-if="cur_order.Status == 'Round1' || cur_order.Status == 'Round2'">
            <el-col :span="24">
              <el-row>
                <b-alert v-if="import_file_warning" show dismissible variant="warning">
                  <span class="alert-text"><strong>Warning!</strong> {{ import_file_warning }}</span>
                </b-alert>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <xlsx-workbook @change="change_for_edit_stocktake" @created="created_for_edit_stocktake">
                    <xlsx-sheet
                      :collection="sheet.data"
                      v-for="sheet in export_sheets_for_edit_stocktake"
                      :key="sheet.name"
                      :sheet-name="sheet.name"
                    />
                    <xlsx-download filename="stocktake_export_product.xlsx" disable-wrapper-click>
                      <template #default="{ download }">
                        <el-button
                          type="primary"
                          size="small"
                          @click="export_for_edit(download)"
                        >
                          {{ $t("Export Product List") }}
                        </el-button>
                      </template>
                    </xlsx-download>
                  </xlsx-workbook>
                </el-col>
                <el-col :span="12" id="stocktake_import">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      {{ $t("Import") + ":" }}
                    </b-input-group-prepend>
                    <b-input-group-append is-text>
                      <section>
                        <input ref="fileuploader" type="file" @change="on_import_change" @click="reset_import_file"/>
                        <xlsx-read :file="import_file" @parsed="import_file_parsed">
                        </xlsx-read>
                      </section>
                    </b-input-group-append>
                  </b-input-group>
                </el-col>
              </el-row>
              <hr />
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-row>
                <el-col :span="22">
                  <el-cascader
                    :placeholder="$t('Department') + ' / ' + $t('Category')"
                    :options="prod_departs"
                    :props="{ multiple: true,
                              value: 'IDn',
                              label: 'Name1',
                              children: 'Categorys',
                              emitPath: 'false' }"
                    style="width: 100%"
                    v-model="cur_category_list"
                    :collapse-tags="true"
                    @change="filter_products_delay"
                    clearable>
                  </el-cascader>
                </el-col>
              </el-row>
            </el-col>
            <!-- <el-col :span="6">
              <b-input-group :prepend="$t('Department') + ' : '">
                <select
                  class="form-control"
                  v-model="cur_department_IDn"
                  @change="changed_depart"
                >
                  <option value="">All</option>
                  <option
                    v-for="(dt, idx) in idxdepartmentlist"
                    :key="idx"
                    :value="dt.IDn"
                  >
                    {{ dt.Name1 }} - {{ dt.Name12 }}
                  </option>
                </select>
              </b-input-group>
            </el-col>
            <el-col :span="6">
              <b-input-group :prepend="$t('Category') + ' : '">
                <select
                  class="form-control"
                  v-model="cur_category_IDn"
                >
                  <option value=""></option>
                  <option
                    v-for="(dt, idx) in categorylist"
                    :key="idx"
                    :value="dt.IDn"
                  >
                    {{ dt.Name1 }} - {{ dt.Name12 }}
                  </option>
                </select>
              </b-input-group>
            </el-col> -->
            <el-col :span="6">
              <b-input-group prepend="PLU/BarCode/Name: ">
                <b-form-input
                  v-model="cur_search_input"
                  @keyup="filter_products_delay"
                ></b-form-input>
              </b-input-group>
            </el-col>
            <!-- <el-col :span="4">
              <b-input-group prepend="Remarks : ">
                <b-form-input
                  class="form-control"
                  v-model="cur_Remarks"
                  @keyup="filter_products_delay"
                ></b-form-input>
              </b-input-group>
            </el-col> -->
            <el-col :span="6">
              <el-row>
                <el-col :span="18" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Show Different Qty") + " : " }}
                </el-col>
                <el-col :span="6">
                  <b-form-checkbox
                    class="mt-2 checkboxcheck form-checkbox"
                    v-model="cur_showdiffqty"
                    value=1
                    unchecked-value=0
                    switch
                    inline
                    @change="filter_products_delay"
                  >
                  </b-form-checkbox>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" v-if="this.cur_order.Status == 'Complete'">
              <el-row>
                <el-col :span="18" class="text-right pr-3 pt-1" style="margin-top: 2px">
                  {{ $t("Show Not Zero Qty") + " : " }}
                </el-col>
                <el-col :span="6">
                  <b-form-checkbox
                    class="mt-2 checkboxcheck form-checkbox"
                    v-model="cur_showNonZero"
                    value=1
                    unchecked-value=0
                    switch
                    inline
                    @change="filter_products_delay"
                  >
                  </b-form-checkbox>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <hr />
          <!-- product list -->
          <section id='stocktakeproductlist'>
            <el-row :gutter="5">
              <el-col :span="3">
                <div class="product_head">PLU</div>
              </el-col>
              <el-col :span="4">
                <div class="product_head">{{ $t("Barcode") }}</div>
              </el-col>
              <el-col :span="7">
                <div class="product_head">{{ $t("Product Name") }}</div>
              </el-col>
              <el-col :span="2">
                <div class="product_head">{{ $t("Unit") }}</div>
              </el-col>
              <el-col :span="2">
                <div class="product_head">{{ $t("SnapShot") }}</div>
              </el-col>
              <el-col :span="2">
                <div class="product_head">{{ $t("1st Qty") }}</div>
              </el-col>
              <el-col :span="2" v-if="cur_order.Status != 'Round1'">
                <div class="product_head">{{ $t("2nd Qty") }}</div>
              </el-col>
              <el-col :span="2" v-if="cur_order.Status == 'Complete'">
                <div class="product_head">{{ $t("Final") }}</div>
              </el-col>
            </el-row>
            <el-row
              :gutter="5"
              v-for="(prod, idx) in cur_Products_paginated"
              :key="idx"
              class="product_row"
            >
              <el-col :span="3">
                <div>{{ prod.PLU }}</div>
              </el-col>
              <el-col :span="4">
                <div>{{ prod.Barcode }}</div>
              </el-col>
              <el-col :span="7">
                <div>{{ prod.Name1 + (prod.Name2 ? (' - ' + prod.Name2) : '') }}</div>
              </el-col>
              <el-col :span="2">
                <div>{{ prod.Unit }}</div>
              </el-col>
              <el-col :span="2">
                <div>{{ prod.InvCaptureQty }}</div>
              </el-col>
              <el-col :span="2">
                <div v-if="cur_order.Status == 'Round1'">
                  <el-input v-model="prod.StockTakeQty1" size="mini" @change="set_edit(prod)" class="round1_qty" @keyup.enter.native="move_to_next(idx, 1)"></el-input>
                </div>
                <div v-else>
                  {{ prod.StockTakeQty1 == null ? '-' : prod.StockTakeQty1 }}
                </div>
              </el-col>
              <el-col :span="2" v-if="cur_order.Status != 'Round1'">
                <div v-if="cur_order.Status == 'Round2'">
                  <el-input v-model="prod.StockTakeQty2" size="mini" @change="set_edit(prod)" class="round2_qty" @keyup.enter.native="move_to_next(idx, 2)"></el-input>
                </div>
                <div v-else>
                  {{ prod.StockTakeQty2 == null ? '-' : prod.StockTakeQty2 }}
                </div>
              </el-col>
              <el-col :span="2" v-if="cur_order.Status == 'Complete'">
                <div>{{ prod.NewInvQty == null ? '-' : prod.NewInvQty }}</div>
              </el-col>
            </el-row>
          </section>
          <el-row style="margin-top: 20px">
            <el-col :span="20">
              <div>
                <b-pagination
                  v-model="current_prod_page"
                  :total-rows="prod_page_total_rows"
                  :per-page="per_prod_page"
                ></b-pagination>
              </div>
            </el-col>
          </el-row>
        </form>
      </div>
      <template #modal-footer v-if="cur_order">
        <xlsx-workbook v-if="cur_order.Status == ''"  @change="change_for_new_stocktake" @created="created_for_new_stocktake">
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in export_sheets_for_new_stocktake"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download filename="stocktake_export_product.xlsx" disable-wrapper-click>
            <template #default="{ download }">
              <button
                type="button"
                class="btn btn-secondary"
                v-if="cur_order.Status == ''"
                @click="form_submit('Export', download);"
              >
                {{ $t("Export Product List") }}
              </button>
            </template>
          </xlsx-download>
        </xlsx-workbook>
        <!-- <button
          type="button"
          class="btn btn-secondary"
          v-if="cur_order.Status == ''"
          @click="form_submit('Export')"
        >
          {{ $t("Export Product List") }}
        </button> -->
        <button
          type="button"
          class="btn btn-primary"
          v-if="cur_order.Status == ''"
          @click="form_submit('Create')"
        >
          {{ $t("Start Stocktake") }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          v-if="cur_order.Status == 'Round1' || cur_order.Status == 'Round2'"
          @click="confirm_notcheck"
        >
          {{ $t("Complete&Update Inventory") }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          v-if="cur_order.Status == 'Round1'"
          @click="form_submit('Round2')"
        >
          {{ $t("Apply 2nd Round") }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-if="cur_order.Status == 'Round1' || cur_order.Status == 'Round2'"
          @click="form_submit('Delete')"
        >
          {{ $t("Delete") }}
        </button>
        <button
          type="button"
          class="btn btn-secondary"
          v-if="cur_order.Status == 'Round1' || cur_order.Status == 'Round2'"
          @click="form_submit('Save')"
        >
          {{ $t("Save") }}
        </button>
        <xlsx-workbook @change="change_for_print_stocktake" @created="created_for_print_stocktake" v-if="cur_order.Status == 'Complete'">
          <xlsx-sheet
            :collection="sheet.data"
            v-for="sheet in export_sheets_for_print_stocktake"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download filename="stocktake_export_product.xlsx" disable-wrapper-click>
            <template #default="{ download }">
              <button
                type="button"
                class="btn btn-secondary"
                @click="export_for_print(download)"
              >
                {{ $t("Print") }}
              </button>
            </template>
          </xlsx-download>
        </xlsx-workbook>
        <button type="button" class="btn btn-secondary" @click="close_my_modal">
          {{ $t("Close") }}
        </button>
      </template>
    </b-modal>
    
    <!-- select how to handle product that not be checked -->
    <b-modal
      ref="notcheck-query-modal"
      :title="$t('Query')"
      id="notcheck-query-modal"
      size="sm"
    >
      <div v-if="cur_order">
        <el-row>
          {{ $t('Some products are with no stocktake qty, would you like to:') }}
        </el-row>
        <el-row>
          <el-radio-group v-model="cur_order.HowToHandleNotCheck" size="small">
            <el-radio :label="'keep_ori'" style="margin-top: 20px;">{{ $t('Keep original value') }}</el-radio>
            <el-radio :label="'set_zero'" style="margin-top: 20px;">{{ $t('Replace the value with 0') }}</el-radio>
          </el-radio-group>
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="submit_notcheck_query">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_notcheck_query_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Select,
  Option,
  Cascader,
  Radio,
  RadioGroup,
} from "element-ui";
// import InputAutocomplete from "../InputAutocomplete";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { XlsxRead, XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Cascader.name]: Cascader,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    XlsxRead,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    flatPicker,
    // InputAutocomplete,
  },
  data() {
    return {
      cur_order: null,
      new_order: {
        IDn: 0,
        LocationID: "",
        OrderID: "",
        StockTakeDate: "",
        Status: "", // Round1 Round2 Complete
        Remarks: "",
        CreateBy: 0,
        UpdateBy: 0,
        isZeroQtyIncluded: 1,
        UpdatePeriod: 0,
        scope: [],
      },
      cur_Products: null,
      cur_Products_paginated: null,
      idxdepartmentlist: null,
      prod_departs: null,
      categorylist: null,
      stocktakelist: null,
      storelist: null,
      employeelist: null,
      productlist: null,

      // export
      export_sheets_for_new_stocktake: [{ name: "stocktake", data: [[1,2,3,4],[5,6,7,8]] }],
      callback_for_new_stocktake: "",
      export_sheets_for_edit_stocktake: [{ name: "stocktake", data: [[1,2,3,4],[5,6,7,8]] }],
      callback_for_edit_stocktake: "",
      export_sheets_for_print_stocktake: [{ name: "stocktake", data: [[1,2,3,4],[5,6,7,8]] }],
      callback_for_print_stocktake: "",

      // import
      import_file: null,
      import_file_warning: "",

      // list search
      search_LocationID: "",
      search_StockTakeDate: "",
      search_CreateBy: "",
      search_Status: "",
      search_Remarks: "",

      // detail search
      cur_department_IDn: 0,
      cur_category_IDn: 0,
      cur_category_list: [],
      cur_search_input: "",
      cur_Remarks: "",
			cur_showdiffqty: 0,
			cur_showNonZero: 0,

      update_period_list: [{name: ' ', value: 0},
                           {name: '1 month', value: 1},
                           {name: '3 month', value: 3},
                           {name: '6 month', value: 6}],

      picker_config: {
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: "today", // New Date();  YYYY-MM-DD HH:MM
      },

      delay_timer: null,
      list_delay_timer: null,
      delay_filter_timer: null,

      cur_product_IDn: 0,
      current_page: 1,
      list_total: 0,
      per_page: 20,
      PrinterIDn: 0,
      IsCombo: -1,
      per_page_option: [10, 20, 50],

      // product pagination
      current_prod_page: 1,
      prod_page_total_rows: 0,
      per_prod_page: 10,

      token: "",
      user_id: 0,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_list();
    },
    current_prod_page: function (newval, oldval) {
      this.paginate_product();
    },
    search_StockTakeDate: function () {
      this.get_list();
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    set_edit(prod) {
      prod.edit = 1;
    },
    handleCommand(selected) {
      this.per_page = selected;
      this.current_page=1;
      this.get_list();
    },
    close_my_modal() {
      this.$refs["my-modal"].hide();
      this.productlist = null;
      this.reset_import_file();
    },
    add_stocktake() {
      this.cur_order = JSON.parse(JSON.stringify(this.new_order));
      this.cur_department_IDn = 0;
      this.cur_category_IDn = 0;
      this.cur_Remarks = "";
      this.cur_category_list = [];
      this.cur_search_input = "";
			this.cur_showdiffqty = 0;
			this.cur_showNonZero = 0;
      this.productlist = null;
      this.reset_import_file();
      this.$refs["my-modal"].show();
    },
    changed_depart() {
      for (var i = 0; i < this.idxdepartmentlist.length; i++) {
        if (this.idxdepartmentlist[i].IDn == this.cur_department_IDn) {
          this.categorylist = this.idxdepartmentlist[i]["Categorys"];
          return;
        }
      }
      this.categorylist = [];
    },
    row_clicked_event(row, event, column) {
      // console.log("row_clicked_event", row, event, column);
      this.cur_order = JSON.parse(JSON.stringify(row));
      this.cur_Products = [];
      this.cur_Products_paginated = [];
      this.cur_department_IDn = 0;
      this.cur_category_IDn = 0;
      this.cur_Remarks = "";
      this.cur_category_list = [];
      this.cur_search_input = "";
			this.cur_showdiffqty = 0;
			this.cur_showNonZero = 0;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Stock_take/stproducts",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.productlist = rt.data.products;
              that.prod_departs = rt.data.prod_departs;
              that.filter_products();
              that.reset_import_file();
              that.import_file_warning = '';
              that.$refs["my-modal"].show();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    filter_products_delay() {
      if (this.delay_filter_timer) {
        clearTimeout(this.delay_filter_timer);
        this.delay_filter_timer = null;
      }
      var that = this;
      this.delay_filter_timer = setTimeout(() => {
        that.filter_products();
      }, 400);
    },
    filter_products() {
      let category_list = [];
      if (this.cur_category_list && this.cur_category_list.length > 0) {
        for (var j = 0; j < this.cur_category_list.length; j++) {
          category_list.push(this.cur_category_list[j].slice(-1)[0]);
        }
      }

      // filter productlist to cur_Product
      this.cur_Products = [];
      if (this.productlist && this.productlist.length > 0) {
        for (var i = 0; i < this.productlist.length; i++) {
          // category filter
          if (this.cur_category_list && this.cur_category_list.length > 0) {
            if (!category_list.includes(this.productlist[i].CategoryIDn)) {
              continue;
            }
          }

          // plu barcode name filter
          if (this.cur_search_input && this.cur_search_input.length > 0) {
            let reg = new RegExp(this.cur_search_input, "i");
            if (
              (!this.productlist[i].PLU || this.productlist[i].PLU.search(reg) < 0) &&
              (!this.productlist[i].Name1 || this.productlist[i].Name1.search(reg) < 0) &&
              (!this.productlist[i].Name2 || this.productlist[i].Name2.search(reg) < 0) &&
              (!this.productlist[i].Barcode || this.productlist[i].Barcode.search(reg) < 0)
            ) {
              continue;
            }
          }
          // if (this.cur_Remarks && this.cur_Remarks.length > 0) {
          //   if (this.productlist[i].Remarks.search(this.cur_Remarks) < 0) {
          //     continue;
          //   }
          // }

          if (this.cur_showdiffqty == '1') {
            if ((!this.productlist[i].StockTakeQty1 && this.productlist[i].StockTakeQty1 !== 0) || (this.productlist[i].StockTakeQty1 == this.productlist[i].InvCaptureQty)) {
              if ((!this.productlist[i].StockTakeQty2 && this.productlist[i].StockTakeQty2 !== 0) || (this.productlist[i].StockTakeQty2 == this.productlist[i].InvCaptureQty)) {
                if ((!this.productlist[i].NewInvQty && this.productlist[i].NewInvQty !== 0) || (this.productlist[i].NewInvQty == this.productlist[i].InvCaptureQty)) {
                  continue;
                }
              }
            }
          }

          if (this.cur_order.Status == 'Complete' && this.cur_showNonZero == '1') {
            if (!this.productlist[i].NewInvQty) {
              continue;
            }
          }

          this.cur_Products.push(this.productlist[i]);
        }
      }

      this.current_prod_page = 1;
      this.prod_page_total_rows = this.cur_Products.length;
      this.paginate_product();
    },
    paginate_product() {
      this.cur_Products_paginated = this.cur_Products.slice((this.current_prod_page - 1) * this.per_prod_page, this.current_prod_page * this.per_prod_page);
    },
    submit_notcheck_query() {
      this.$refs["notcheck-query-modal"].hide();
      this.form_submit('Complete');
    },
    close_notcheck_query_model() {
      this.$refs["notcheck-query-modal"].hide();
      this.cur_order.HowToHandleNotCheck = null;
    },
    move_to_next(idx, type) {
      var inputs = document.getElementsByClassName(type == 1 ? "round1_qty" : "round2_qty");
      if (idx + 1 <= inputs.length - 1) {
        inputs[idx + 1].children[0].focus();
      }
    },
    // for add
    change_for_new_stocktake(sheet) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_new_stocktake && sheet.SheetNames && sheet.SheetNames.length > 0) {
        this.callback_for_new_stocktake();
      }
    },
    created_for_new_stocktake() {
      this.callback_for_new_stocktake = '';
    },
    // for edit
    change_for_edit_stocktake(sheet) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_edit_stocktake && sheet.SheetNames && sheet.SheetNames.length > 0) {
        this.callback_for_edit_stocktake();
      }
    },
    created_for_edit_stocktake() {
      this.callback_for_edit_stocktake = '';
    },
    export_for_edit(callback) {
      this.export_sheets_for_edit_stocktake[0].data = this.generate_excel_data(this.cur_Products, false);
      this.callback_for_edit_stocktake = callback;
    },
    // for print
    change_for_print_stocktake(sheet) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_print_stocktake && sheet.SheetNames && sheet.SheetNames.length > 0) {
        this.callback_for_print_stocktake();
      }
    },
    created_for_print_stocktake() {
      this.callback_for_print_stocktake = '';
    },
    export_for_print(callback) {
      this.export_sheets_for_print_stocktake[0].data = this.generate_excel_data(this.cur_Products, true);
      this.callback_for_print_stocktake = callback;
    },
    generate_excel_data(export_products, bprint) {
      let excel_data = [];
      if (bprint) {
        excel_data.push(['PLU','BarCode','Name1','Name2','Pack','Size','Unit','SnapShot','1stQty','2ndQty','OldInvQty','NewInvQty']);
      } else {
        excel_data.push(['PLU','BarCode','Name1','Name2','Pack','Size','Unit','Qty']);
      }
      
      for (var i = 0; i < export_products.length; i++) {
        let l = export_products[i].PackL ? export_products[i].PackL : '';
        let m = export_products[i].PackM ? export_products[i].PackM : '';
        let s = export_products[i].PackS ? export_products[i].PackS : '';
        let pack = l ? l + 'x' + m + 'x' + s : '';
        let row = [export_products[i].PLU,
                   export_products[i].Barcode,
                   export_products[i].Name1,
                   export_products[i].Name2,
                   pack,
                   export_products[i].Size,
                   export_products[i].Unit];
        if (bprint) {    // SnapShot,1stQty,2ndQty,OldInvQty,NewInvQty
          row.push(export_products[i].InvCaptureQty);
          row.push(export_products[i].StockTakeQty1);
          row.push(export_products[i].StockTakeQty2);
          row.push(export_products[i].OldInvQty);
          row.push(export_products[i].NewInvQty);
        } else {
          row.push('');  // qty
        }
        excel_data.push(row);
      }
      return excel_data;
    },
    on_import_change(event) {
      this.import_file = event.target.files ? event.target.files[0] : null;
    },
    reset_import_file() {
      this.import_file = null;
      if (this.$refs.fileuploader) {
        this.$refs.fileuploader.value = '';
      }
    },
    import_file_parsed(filedata) {
      this.import_file_warning = "";
      this.selected_product_list = [];
      var sheets = filedata.Sheets[Object.keys(filedata.Sheets)[0]];
      var a = sheets["A1"] != undefined ? sheets["A1"].w.trim() : "";
      var b = sheets["B1"] != undefined ? sheets["B1"].w.trim() : "";
      var c = sheets["C1"] != undefined ? sheets["C1"].w.trim() : "";
      var d = sheets["D1"] != undefined ? sheets["D1"].w.trim() : "";
      var e = sheets["E1"] != undefined ? sheets["E1"].w.trim() : "";
      var f = sheets["F1"] != undefined ? sheets["F1"].w.trim() : "";
      var g = sheets["G1"] != undefined ? sheets["G1"].w.trim() : "";
      var h = sheets["H1"] != undefined ? sheets["H1"].w.trim() : "";
      var find;
      if (a != "PLU" ||
          b != "BarCode" ||
          c != "Name1" ||
          d != "Name2" ||
          e != "Pack" ||
          f != "Size" ||
          g != "Unit" ||
          h != "Qty") {
        this.import_file_warning = "Columns of import File are wrong";
        return;
      }
      for (var idx = 2; idx < 10000; idx++) {
        // Max 10000 for safe
        a = sheets["A" + idx] != undefined ? sheets["A" + idx].w.trim() : "";
        b = sheets["B" + idx] != undefined ? sheets["B" + idx].w.trim() : "";
        c = sheets["C" + idx] != undefined ? sheets["C" + idx].w.trim() : "";
        d = sheets["D" + idx] != undefined ? sheets["D" + idx].w.trim() : "";
        var qty = sheets["H" + idx] != undefined ? sheets["H" + idx].w.trim() : "";
        if (!a && !b && !c && !d) {
          break;
        }

        if (qty == '') {
          continue;
        }

        qty = parseFloat(qty);  // qty
        if (isNaN(qty)) {
          this.import_file_warning = "Qty is not a number at line " + idx;
          return;
        }
        qty = qty.toFixed(2);

        find = false;
        for (var i = 0; i < this.productlist.length; i++) {
          let product = this.productlist[i];
          if (
                (a != '' && product.PLU == a) ||
                (b != '' && product.Barcode == b) ||
                (c != '' && product.Name1 == c) ||
                (d != '' && product.Name2 == d)
              ) {
            if (this.cur_order.Status == 'Round1') {
              product.StockTakeQty1 = qty;
              product.edit = 1;
            }
            if (this.cur_order.Status == 'Round2') {
              product.StockTakeQty2 = qty;
              product.edit = 1;
            }
            find = true;
            break;
          }
        }
        if (!find) {
          this.import_file_warning = "Can't find product at line " + idx;
          return;
        }
      }
    },
    confirm_notcheck() {
      // check whether every product is checked
      var ask = false;
      for (var i = 0; i < this.productlist.length; i++) {
        if (this.productlist[i].StockTakeQty1 == null && this.productlist[i].StockTakeQty2 == null) {
          ask = true;
        }
      }
      if (ask) {
        this.cur_order.HowToHandleNotCheck = 'keep_ori';
        this.$refs["notcheck-query-modal"].show();
        return;
      }
      this.cur_order.HowToHandleNotCheck = null;
      this.form_submit('Complete');
    },
    async form_submit(opt, callback) {
      if (opt == "Create" || opt == "Export") {
        if (!this.cur_order.LocationID) {
          alert("Please select store");
          return;
        }
        if (this.cur_order.scope.length == 0) {
          alert("Please select scope");
          return;
        }
      }

      var bodyFormData = new FormData();
      var that = this;
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);      
      bodyFormData.append("Order", JSON.stringify(this.cur_order));
      bodyFormData.append("Status", opt);
      bodyFormData.append("products", JSON.stringify(this.productlist));

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Stock_take/submit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (opt == "Export") {
                // do export
                let export_products = rt.data;
                that.export_sheets_for_new_stocktake[0].data = that.generate_excel_data(export_products, false);
                that.callback_for_new_stocktake = callback;
                return;
              }
              that.get_list();
              that.close_my_modal();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_list_delay() {
      if (this.list_delay_timer) {
        clearTimeout(this.delay_timer);
        this.list_delay_timer = null;
      }
      var that = this;
      this.list_delay_timer = setTimeout(() => {
        that.get_list();
      }, 400);
    },
    async get_list() {
      if (this.list_delay_timer) {
        clearTimeout(this.list_delay_timer);
        this.list_delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.search_LocationID) {
        bodyFormData.append("LocationID", this.search_LocationID);
      }
      if (this.search_StockTakeDate) {
        bodyFormData.append("StockTakeDate", this.search_StockTakeDate);
      }
      if (this.search_CreateBy) {
        bodyFormData.append("CreateBy", this.search_CreateBy);
      }
      if (this.search_Status) {
        bodyFormData.append("Status", this.search_Status);
      }
      if (this.search_Remarks) {
        bodyFormData.append("Remarks", this.search_Remarks);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Stock_take",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.employeelist = rt.data.employees;
              that.idxdepartmentlist = rt.data.idxdepartments;
              that.stocktakelist = rt.data.stocktakes;
              that.storelist = rt.data.stores;
              that.page_total = rt.data.list_total;
              that.per_page = rt.data.per_page;
              // that.form_cancel();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.card .table td {
  padding: 0.5rem;
}
.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.allstr {
  margin-top: -0.5rem;
}
.outbound_product_section {
  max-height: 30rem;
  overflow: auto;
}
#stocktakelisttable th {
  padding: 0;
}
#stocktakelisttable .labeldiv {
  line-height: 1rem;
  margin-top: 0.5rem;
  font-weight: 900;
}
.form-group {
  margin-bottom: 0.5rem;
}
#stocktakeproductlist .product_row {
  border-top: 1px solid gray;
  padding-top: 5px;
  padding-bottom: 5px;
}
#stocktake_import .input-group-text {
  padding: 5px 10px;
}
.el-cascader{
  width: 80%;
}
.el-cascader-panel {
  height: 320px;
}
.el-cascader-menu {
  height: 320px;
  max-height: 320px;
}
.el-cascader-menu__wrap {
  height: 320px;
}
.el-cascader-menu__wrap.el-scrollbar__wrap {
  height: 320px;
}
.el-cascader__tags .el-tag:not(.is-hit){
  font-size: 18px;
}
.el-cascader-menu__list{
  min-width: 250px;
  min-height: 300px;
  max-height: 300px;
  font-size: 18px;
  overflow-x: hidden;
}
</style>
